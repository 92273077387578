import React from "react";
import styled from "styled-components";

import img1 from "../../assets/Nfts/Amalendu.jpg";
import img2 from "../../assets/Nfts/Vedant.jpg";
import img3 from "../../assets/Nfts/Manan.jpg";
// import img4 from "../../assets/Nfts/bighead-3.svg";
// import img5 from "../../assets/Nfts/bighead-4.svg";
// import img6 from "../../assets/Nfts/bighead-5.svg";
// import img7 from "../../assets/Nfts/bighead-6.svg";
// import img8 from "../../assets/Nfts/bighead-7.svg";
// import img9 from "../../assets/Nfts/bighead-8.svg";
import ConfettiComponent from "../Confetti";

const Section = styled.section`
  min-height: 100vh;
  width: 100vw;
  background-color: ${(props) => props.theme.body};
  position: relative;
`;

const Title = styled.h1`
  font-size: ${(props) => props.theme.fontxxl};
  text-transformation: capitalize;
  color: ${(props) => props.theme.text};
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 1rem auto;
  border-bottom: 2px solid ${(props) => props.theme.text};
  width: fit-content;
  margin-top: 100px; 

  @media (max-width: 40em) {
    font-size: ${(props) => props.theme.fontxl};
  }
`;

const Container = styled.div`
  width: 75%;
  margin: 2rem auto;

  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;

  @media (max-width: 64em) {
    width: 80%;
  }
  @media (max-width: 48em) {
    width: 90%;
    justify-content: center;
  }
  
`;

const Center = styled.div`
  width: 75%;
  margin: 2rem auto;
  text-align: center;
  display: auto;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;

  @media (max-width: 64em) {
    width: 80%;
  }
  @media (max-width: 48em) {
    width: 90%;
    justify-content: center;
  }
`;

const Item = styled.div`
  width: calc(20rem - 4vw);
  padding: 1rem 0;
  color: ${(props) => props.theme.body};
  margin: 2rem 1rem;
  position: relative;
  z-index: 5;

  backdrop-filter: blur(4px);

  border: 2px solid ${(props) => props.theme.text};
  border-radius: 20px;
  &:hover {
    img {
      transform: rotateY(180deg);
    }
  }


  @media (max-width: 30em) {
    width: 70vw;
  }
`;

// const ImageContainer = styled.div`
//   width: 80%;
//   margin: 0 auto;
//   background-color: ${(props) => props.theme.carouselColor};
//   border: 1px solid ${(props) => props.theme.text};
//   padding: 1rem;

//   border-radius: 20px;
//   cursor: pointer;

//   img {
//     width: 100%;
//     height: auto;
//     transition: all 0.3s ease;
//   }
// `;

const ImageContainer = styled.div`
  position: relative;
  margin: 0 auto;
  padding: 1rem;
  border-radius: 20px;
  cursor: pointer;
  overflow: hidden;

  img {
    width: 100%;
    height: auto;
    object-fit: cover;
    transition: all 0.3s ease;
    border-radius: 20px;
  }

  .overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    opacity: 0;
    transition: opacity 0.3s ease;
  }

  &:hover {
    .overlay {
      opacity: 1;
    }

    img {
        transform: rotateY(180deg);
      opacity: 0;
    }
  }
`;

const Name = styled.h2`
  font-size: ${(props) => props.theme.fontlg};
  display: flex;
  align-items: center;
  justify-content: center;
  text-transform: uppercase;
  margin-top: 1rem;
  color: ${(props) => `rgba(${props.theme.textRgba}, 0.9)`};
`;

const Flip = styled.h2`
  font-size: 200px;
  display: flex;
  align-items: center;
  justify-content: center;
  text-transform: uppercase;
  margin-top: 1rem;
  color: ${(props) => props.color || 'black'};
`;

const Position = styled.h2`
  font-size: ${(props) => props.theme.fontmd};
  display: flex;
  align-items: center;
  justify-content: center;
  text-transform: capitalize;
  color: ${(props) => `rgba(${props.theme.textRgba}, 0.9)`};
  font-weight: 400;
`;

// const MemberComponent = ({ img, name = " ", position = " " }) => {
//   return (
//     <Item>
//       <ImageContainer>
//         <img src={img} alt={name} />
//       </ImageContainer>
//       <Name>{name}</Name>
//       <Position>{position}</Position>
//     </Item>
//   );
// };

const MemberComponent = ({ img, name = " ", position = " ", initial = " ", color=""}) => {
  
    return (
      <Item>
        <ImageContainer>
              <img src={img} alt={name} />
              <div className="overlay">
            <Flip color={color}>
              {name.charAt(0)}
            </Flip>
              </div>
        </ImageContainer>
        <Name>{name}</Name>
        <Position>{position}</Position>
      </Item>
    );
  };

  
const Team = () => {
  return (
    <Section id="team">
      <ConfettiComponent />
      <Title>THE BOARD</Title>
      <Container>
        <MemberComponent img={img1} name="Amalendu Guru" position="Co-Founder" color="rgb(120 118 117)" />
        <MemberComponent img={img2} name="Vedant Tulsyan" position="Co-Founder" color="rgb(7 106 146)"/>
        <MemberComponent img={img3} name="Manan Santoki" position="Co-Founder" color="mediumseagreen"/>
        {/* <MemberComponent img={img4} name="BLACK PANTHER" position="Manager" />
        <MemberComponent img={img5} name="DEATHSTROKE" position="Artist" />
        <MemberComponent
          img={img6}
          name="LAZY KONG"
          position="Social Media Manager"
        />
        <MemberComponent
          img={img7}
          name="CYBER PUNK"
          position="Blockchain Specialist"
        />
        <MemberComponent img={img8} name="MONK" position="Web3 Developer" />
        <MemberComponent img={img9} name="BANANA" position="Graphic Designer" /> */}
      </Container>
      <Title>TEAM</Title>
      <Center >
        <br/>
      <h1 >Ready to Join Us ?</h1>
      </Center>
    </Section>
  );
};

export default Team;
