import React from "react";
import styled from "styled-components";
import { Swiper, SwiperSlide } from "swiper/react";
import { Pagination, Navigation, Autoplay, EffectCards } from "swiper";
import Countdown from "react-countdown";

import "swiper/css";
import "swiper/css/effect-cards";
import "swiper/css/pagination";
import "swiper/css/navigation";

import Arrow from "../assets/Arrow.svg";
import border from "../assets/border.svg";
const Container = styled.div`
  position: relative;
  width: 40vw;
  height: 40vh;
  border: 10px solid transparent;

  @media (max-width: 70em) {
    height: 60vh;
  }

  @media (max-width: 64em) {
    height: 50vh;
    width: 30vw;
  }

  @media (max-width: 48em) {
    height: 50vh;
    width: 40vw;
  }

  @media (max-width: 30em) {
    height: 45vh;
    width: 60vw;
  }

  .swiper {
    width: 100%;
    height: 100%;
  }

  .swiper-slide {
    background-color: ${(props) => props.theme.carouselColor};
    border-radius: 20px;

    display: flex;
    justify-content: center;
    align-items: center;

    font-size: 2rem;
    font-weight: bold;
    color: ${(props) => props.theme.text};
  }

  .swiper-button-next,
  .swiper-button-prev {
    position: absolute;
    color: ${(props) => props.theme.text};
    width: 4rem;
    top: 60%;
    right: -20px;
    background-image: url(${Arrow});
    background-position: center;
    background-size: cover;

    &:after {
      display: none;
    }

    @media (max-width: 64em) {
      width: 3rem;
    }

    @media (max-width: 30em) {
      width: 2rem;
    }
  }
`;

const CountdownTimer = () => {
  const targetDate = new Date("2024-02-01T00:00:00Z");

  return (
    <Countdown date={targetDate} renderer={({ days, hours, minutes, seconds }) => (
      <div style={{ textAlign: 'center' }}>
        <h2>Dropping in</h2>
        <div>
          {days}d {hours}h {minutes}m {seconds}s
        </div>
      </div>
    )} />
  );
};

const Carousel = () => {
  return (
    <Container>

        <SwiperSlide>
          <CountdownTimer />
        </SwiperSlide>
  
    </Container>
  );
};

export default Carousel;
