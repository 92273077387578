import React, { useRef, useState } from "react";
import styled from "styled-components";

const Section = styled.section`
  min-height: 100vh;
  width: 100vw;
  background-color: ${(props) => props.theme.text};
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: relative;
  overflow: hidden;

  & > *:first-child {
    animation-duration: 20s;

    @media (max-width: 30em) {
      animation-duration: 15s;
    }
  }
  & > *:last-child {
    animation-duration: 15s;

    @media (max-width: 30em) {
      animation-duration: 10s;
    }
  }
`;

const ProjectContainer = styled.div`
  margin-bottom: 10px;
  color: white;
  width: 100%;
`;

const ProjectHeader = styled.a`
  display: block;
  cursor: pointer;
  padding: 10px;
  border-radius: 5px;
  background-color: ${(props) => props.active ? "#222" : "transparent"};
  transition: background-color 0.3s ease;
  text-decoration: none; /* Remove default link styling */
`;

const ProjectContent = styled.div`
  overflow: hidden;
  max-height: ${(props) => props.active ? "1000px" : "0"};
  transition: max-height 0.5s ease;
`;

const ProjectName = styled.span`
  margin-left: 10px;
`;

const ProjectDescription = styled.div`
  margin-top: 5px;
  padding: 10px;
  border: 1px solid lightgray;
  border-radius: 5px;
`;

const Showcase = () => {
  const [activeProject, setActiveProject] = useState(null);

  const projects = [
    { name: "Android Open Source Project (AOSP, XDA-Devs)", description: "Compiled and Enhanced AOSP OS for mobile devices. Kernel Development", link: "https://example.com/project1" },
    { name: "Land Marketplace Platform", description: "Developed a full-stack web application using the MERN (MongoDB, Express.js, React.js, Node.js) stack to facilitate land buying and selling processes. The platform allows users to search and view detailed information about available land parcels, including location data visualized through the integration of the Google Maps API. Implemented a custom tool and specification that enabled users to purchase land directly through the application, with a seamless payment experience provided by the Razorpay payment gateway integration. Utilized MongoDB as the backend database to store and retrieve land-related data efficiently.", link: "https://bhoopati.com/" },
    { name: "Fine-tuning LLM Model with Qlora Configuration", description: "Conducted fine-tuning of a Large Language Model (LLM) using a custom dataset and the Qlora configuration. Implemented adjustments to model weights to optimize performance for specific tasks.", link: "https://huggingface.co/MananSantoki" },
    { name: "Developed an AI Chatbot using Langchain RAG", description: "Allows users to embed their documents for enhanced interaction. Integrated various open-source models for versatile conversational experiences. Leveraged Python, PyTorch, and Hugging Face Transformers. Proficient in NLP, model integration, and API development. Tech: Python, Flask, Hugging Face Transformers, Docker, FAISS", link: "https://github.com/S-W-Engineering/chat-with-dat" },
    { name: "CCMS - Coaching Class Management System (Python, MySQL, Terminal)", description: "(HSC Project) Terminal UI coaching class management to help with management of data for staff, students and faculty for small & mid scale enterprises.", link: "https://github.com/VedantTulsyan/CCMSa" },
    { name: "Parking Management System", description: "Project maintains records and allot parking to vehicles accurately in any big parking space such as mall, college campus.", link: "https://github.com/VedantTulsyan/SmartParkingManagementSystem" },
    { name: "XpertiseX", description: "XpertiseX is an advanced ed-tech platform designed to revolutionize the way users create, consume, and rate educational content. Built on the robust MERN stack, including ReactJS, NodeJS, MongoDB, and ExpressJS, XpertiseX offers a seamless and interactive learning experience for students while providing a platform for instructors to showcase their expertise worldwide.", link: "https://xpertisex.vercel.app/" }
  ];

  const handleProjectClick = (index) => {
    setActiveProject(activeProject === index ? null : index);
  };

  return (
    <div>
      <Section id="showcase">
        <h1 style={{ color: 'white' }}>SOME OF OUR WORK</h1>
          {projects.map((project, index) => (
            <ProjectContainer key={index}>
              <ProjectHeader href={project.link} target="_blank" active={activeProject === index} onClick={() => handleProjectClick(index)}>
                <ProjectName>{project.name}</ProjectName>
              </ProjectHeader>
              <ProjectContent active={activeProject === index}>
                <ProjectDescription>{project.description}</ProjectDescription>
              </ProjectContent>
            </ProjectContainer>
          ))}
      </Section>
    </div>
  );
};

export default Showcase;
