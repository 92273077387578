import gsap from "gsap";
import ScrollTrigger from "gsap/ScrollTrigger";
import React, { useLayoutEffect, useRef } from "react";
import styled from "styled-components";
import Accordion from "../Accordion";

const Section = styled.section`
  min-height: 100vh;
  height: auto;
  width: 100vw;
  background-color: ${(props) => props.theme.text};
  position: relative;
  color: ${(props) => props.theme.body};

  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
`;

const Title = styled.h1`
  font-size: ${(props) => props.theme.fontxxl};
  text-transformation: uppercase;
  color: ${(props) => props.theme.body};

  margin: 1rem auto;
  border-bottom: 2px solid ${(props) => props.theme.cauroselColor};
  width: fit-content;

  @media (max-width: 48em) {
    font-size: ${(props) => props.theme.fontxl};
  }
`;

const Container = styled.div`
  width: 75%;
  margin: 2rem auto;

  display: flex;
  justify-content: space-between;
  align-items: center;

  @media (max-width: 64em) {
    width: 80%;
  }

  @media (max-width: 48em) {
    width: 90%;
    flex-direction: column;
  }

  & > *::last-child {
    & > *::first-child {
      margin-top: 0;
    }
  }
`;

const Box = styled.div`
  width: 45%;

  @media (max-width: 64em) {
    width: 90%;
    align-self: center;
  }
`;

const Faq = () => {
  const ref = useRef(null);
  gsap.registerPlugin(ScrollTrigger);
  useLayoutEffect(() => {
    let element = ref.current;

    ScrollTrigger.create({
      trigger: element,
      start: "top top",
      end: "bottom top",
      pin: true,
      pinSpacing: false,
      scrub: true,
      // markers: true,
    });

    return () => {
      ScrollTrigger.kill();
    };
  }, []);

  return (
    <Section ref={ref} id="faq">
      <Title>FAQ</Title>
      <Container>
        <Box>
          <Accordion title="CAN I GET TO KNOW MORE ABOUT YOU AND YOUR GOALS?">
            We know you might be excited about us and want to know more about us.
            But man. You gotta chill. We are not ready yet. We are still an infant.
          </Accordion>
          <Accordion title="WHO ARE WE HOW DID WE STARTED?">
            We are some guys who are still in college and want to make something
            build something. And are keen to learn new things. We have a great vision and we are working towards it.
          </Accordion>
          <Accordion title="WHY SHOULD YOU CONSIDER US?">
          Well. You'll see about that. SOON :)
          </Accordion>
        </Box>
        <Box>
          <Accordion title="HOW CAN I CONTACT YOU?">
          You can reach us out at contact@avmmindscape.com
          </Accordion>
          <Accordion title="WHAT SERVICES ARE YOU GOING TO PROVIDE?">
            About that, we have a lot of things in our mind. Right now we are providing
            web development and design services. But we are planning to expand our services
            to Devops, Mobile App Development, Machine Learning and AI, and many more.
          </Accordion>
          <Accordion title="I AM EXCITED ABOUT YOU AND WANT TO JOIN.">
            Ahh most welcome. Contact use at contact@avmmindscape.com and we will get back to you. 
          </Accordion>
        </Box>
      </Container>
    </Section>
  );
};

export default Faq;
